// @flow
import {navigate} from 'gatsby';
import React, {useEffect, useState} from 'react';

import CheckoutSection from '_components/CheckoutSection';
import FullPageSpinner from '_components/FullPageSpinner';
import Layout from '_components/Layout';
import {useAuth, useAuthState} from '_contexts/auth';
import {CheckoutProvider} from '_contexts/checkout';
import useAnalytics, {event} from '_hooks/useAnalytics';
import {checkHasActiveSubscription} from '_utils/subscription';

const METADATA = {campaign: 'rtm'};

const RTMCheckout = (): React$Node => {
  const {track} = useAnalytics();
  const [loadingPage, setLoadingPage] = useState(true);

  const {setMetadata} = useAuth();

  const {loading: gettingCurrentUser, currentUser} = useAuthState();

  useEffect(() => {
    navigate(`?code=RITUALRTM`);
  }, []);

  useEffect(() => {
    track({
      group: event.group.Checkout,
      action: event.action.Viewed,
    });
  }, [track]);

  useEffect(() => {
    setMetadata(METADATA);
  }, [setMetadata]);

  useEffect(() => {
    if (gettingCurrentUser) return;

    if (checkHasActiveSubscription(currentUser)) {
      navigate('/profile');
    } else {
      setLoadingPage(false);
    }
  }, [gettingCurrentUser, currentUser]);

  if (loadingPage) {
    return <FullPageSpinner />;
  }

  return (
    <CheckoutProvider>
      <Layout floatingNavbar={false} inverseFooter navbarTemplate="checkout">
        <CheckoutSection campaign={METADATA.campaign} />
      </Layout>
    </CheckoutProvider>
  );
};

export default RTMCheckout;
